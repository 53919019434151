import React from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import LoginForm from '../components/ProfileAuthentication/LoginForm'
import { navigate } from 'gatsby'

if (typeof window !== 'undefined' && localStorage.getItem('user')) navigate('/')

const Signin = () => {
    return (
        <Layout title='Iniciar Sesión'>
            <PageBanner
                pageTitle='Iniciar Sesión'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Iniciar Sesión'
            />
            <LoginForm />
        </Layout>
    )
}

export default Signin