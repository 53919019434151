import React from 'react'
import { Link } from 'gatsby'
import { useState } from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import api from '../../api/credentials'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const LoginForm = () => {
    const [user, setUser] = useState({
        email: '',
        phone: '',
        password: ''
    })
    const [validation, setValidation] = useState({
        email: null,
        password: null,
        phone: null,
    })
    const [error, setError] = useState(null)

    const onInputChange = e => {
        setError(null)
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    const onUsernameChange = e => {
        setError(null)
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( e.target.value)) {
            setUser({ ...user, ['email']: e.target.value })
        }
        else{
            setUser({ ...user, ['phone']: e.target.value })
        }
    }
    const signin = () => {
        api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
            api.post(`${process.env.API_URL}/signin`, user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                setError(null)
                localStorage.setItem('user', JSON.stringify(response.data))
                window.location.href = '/'
            }).catch(error => {
                setValidation({
                    email: error.response.data.errors?.email ? error.response.data.errors.email[0] : (error.response.data.errors?.phone ? error.response.data.errors.phone[0] : null),
                    password: error.response.data.errors?.password ? error.response.data.errors.password[0] : null
                })
                setError(error.response.data.errors?.email ?? 'Hubo un error al iniciar sesión, contacte con la administración')
            })
        })

    }

    const handleClose = () => {
        setError(null)
    }

    return (
        <div className='signin-area ptb-100'>
            <div className='container'>

                <div className='login-form'>
                    <form>
                        <div className='form-group'>
                            <label htmlFor='email'>Correo Electrónico / Teléfono</label>
                            <input type='email' name='email' className='form-control' placeholder='Correo Electrónico / Teléfono' onChange={e => onUsernameChange(e)} />
                            {validation.email && <span className='text-danger'>{validation.email}</span>}
                            {validation.phone && <span className='text-danger'>{validation.phone}</span>}

                        </div>
                        <div className='form-group'>
                            <label htmlFor='password'>Contraseña</label>
                            <input type='password' name='password' className='form-control' placeholder='Contraseña' onChange={e => onInputChange(e)} />
                            {validation.password && <span className='text-danger'>{validation.password}</span>}

                        </div>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Link to='/signup' className='align-self-center'>Regístrate</Link>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap'>
                                <a href={`${process.env.BACKEND_URL}/password/reset`} className='lost-your-password'>¿Has olvidado tu contraseña?</a>
                            </div>
                        </div>
                        <button type='button' onClick={signin}>Iniciar Sesión</button>
                    </form>

                </div>
                <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}

export default LoginForm